<template>
  <div class="pagination">
    <div class="pagination__info">
      Страница {{ currentPage }} из {{ pageCount }}
    </div>

    <div class="pagination__pages">
      <VButton
        icon="arrow-pagination"
        block="pagination"
        size="init"
        class="prev-page"
        :disabled="disabled || isFirstPage"
        @click.native="setPage(currentPage - 1)"
      />

      <button
        :class="{ active: 1 === currentPage }"
        @click.stop="setPage(1)"
        :disabled="disabled"
      >
        1
      </button>

      <span v-if="currentPage > 4 && pageCount >= 5">...</span>

      <button
        v-for="page in pagination"
        :key="page"
        :class="{ active: page === currentPage }"
        @click="setPage(page)"
        :disabled="disabled"
      >
        {{ page }}
      </button>

      <span v-if="pageCount > 5 && currentPage <= pageCount - 2">...</span>

      <button
        v-if="pageCount !== 1"
        :disabled="disabled"
        :class="{ active: pageCount === currentPage }"
        @click.stop="setPage(pageCount)"
      >
        {{ pageCount }}
      </button>

      <VButton
        :icon="{ name: 'arrow-pagination', dir: '180' }"
        block="pagination"
        size="init"
        class="last-page"
        :disabled="disabled || isLastPage"
        @click.native="setPage(currentPage + 1)"
      />
    </div>
  </div>
</template>

<script>
import Vue from 'vue'
import VButton from '@/components/simple/button/VButton'

import { createPageList } from '@/utils/store/pagination'

export default Vue.extend({
  name: 'AppPagination',
  components: { VButton },
  props: {
    currentPage: Number,
    pageCount: Number,
    disabled: {
      type: Boolean,
      default: false
    }
  },
  computed: {
    isFirstPage () {
      return this.currentPage === 1
    },

    isLastPage () {
      return this.currentPage === this.pageCount
    },

    pagination () {
      if (this.currentPage < 5) {
        return this.pageList.filter(page => page > 1 && page < 5 && page !== this.pageCount)
      }

      if (this.currentPage > this.pageCount - 3) {
        return this.pageList.filter(page => page > this.pageCount - 4 && page !== this.pageCount)
      }

      return this.pageList.filter(page => page >= this.currentPage - 1 && page <= this.currentPage + 1)
    },

    pageList () {
      return createPageList(this.pageCount)
    }
  },
  methods: {
    setPage (pageNumber) {
      this.$emit('setPage', pageNumber)
    }
  }
})
</script>

<style scoped lang="scss">
.pagination  {
  display: flex;
  padding: 30px 0;
  gap: 40px;

  &__pages {
    display: flex;
  }

  @media screen and (max-width: $tablet-width-s) {
    &__pages {
      & button {
        padding: 0 10px;
      }
      & .prev-page {
        padding-left: 0;
      }

      & .next-page {
        padding-right: 0;
      }
    }

  }

  @media screen and (max-width: 400px) {
    flex-direction: column;
    gap: 10px;
  }
}

.active {
  color: $color__main;
  pointer-events: none;
}
</style>
